<template>
  <div>
    <p>Feedback successfully submitted!</p>
    <p>Thank you for getting in touch!</p>
  </div>
</template>

<script>
export default {
  name: "FeedbackThanks"
}
</script>

<style scoped>

</style>