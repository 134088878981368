<template>
  <div class="container">
    <News/>
  </div>
</template>

<script>
import News from "@/components/about/News";

export default {
  name: "About",
  components: {News}
}
</script>

<style scoped>

</style>