<template>
  <div class="row">
    <div class="col-md-10 m-auto">
      <h3>Highlights</h3>
      <ul>
        <li>Added Hero Floor prices to the Personal page</li>
        <li>Dark mode is available if your OS/mobile/browser theme is set to dark mode</li>
      </ul>

      <h3>Recent changes</h3>


      <table class="table border">
        <tbody>
        <tr>
          <td class="text-start">2021.11.16 21:00 CET</td>
          <td class="text-start">Added heroes with floor price to the personal page</td>
        </tr>
        <tr>
          <td class="text-start">2021.11.11 17:09 CET</td>
          <td class="text-start">Changed the timespan of the Bank APR from 168 hours (1 week) to 31 days</td>
        </tr>
        <tr>
          <td class="text-start">2021.11.10 10:30 CET</td>
          <td class="text-start">Remove the menu link for the old pools page. Page still available through old bookmarked links</td>
        </tr>
        <tr>
          <td class="text-start">2021.11.08 20:53 CET</td>
          <td class="text-start">Add Top 10 Greatest Tear Pulls to Quest landing page</td>
        </tr>
        <tr>
          <td class="text-start">2021.10.31 13:54 CET</td>
          <td class="text-start">Dark mode is available if your OS/mobile/browser theme is set to dark mode</td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  name: "News"
}
</script>

<style scoped>

</style>